@import 'bootstrap/dist/css/bootstrap.min.css';

.cookiesModal .accordion .card {
    background-color: white !important;
    border: 0 !important;
}

.cookiesModal .accordion .card .card-header {
    background-color: #00000008 !important;
}

.cookiesModal .accordion .card .card-header {
    border-radius: 0 !important;
}

.cookiesModal .accordion .card:first-child .card-header {
    border-radius: 0.375rem 0.375rem 0 0  !important;
}

.cookiesModal .accordion .card:last-child .card-header {
    border-radius: 0 0 0.375rem 0.375rem !important;
}

.btn-danger, .btn-outline-danger {
    border-color: #f44336 !important;
    color: #f44336 !important;
}
.btn-danger:hover, .btn-outline-danger:hover {
    border-color: #f44336 !important;
    color: #ffffff !important;
}

.btn-outline-success {
    border-color: #198754 !important;
    color: #198754 !important;
}

.btn-outline-success:hover {
    border-color: #198754 !important;
    color: #ffffff !important;
}

.bg-gray-200 {
    background-color: rgb(238, 238, 238);
}