body {
  font-family: "Poppins", sans-serif;
}

.max-w-3 {
  max-width: 58rem;
}

.max-w-4 {
  max-width: 30rem;
}

.max-w-5 {
  max-width: 62rem;
}

.bg-cover {
  background-size: cover;
}

.bg-cover-1 {
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
}

.card-4 {
  border-radius: 1.875rem;
  background-color: #fff;
  box-shadow: 4px 10px 30px 0 #0000000f;
}

.max-w-6 {
  max-width: 35rem;
}
